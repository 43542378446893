<template>
  <div class="systemUser">
    <div class="page-title">系统用户管理</div>
    <div class="row-me row-center flex-wrap mT20">
      <div>模糊搜索</div>
      <div class="w200 mL10">
        <el-input placeholder="请输入用户账号" style="width: 100%;" clearable v-model="account"></el-input>
      </div>
      <div class="mL20">用户姓名</div>
      <div class="w200 mL10">
        <el-input placeholder="请输入用户姓名" style="width: 100%;" clearable v-model="name"></el-input>
      </div>
      <div class="mL20">用户角色</div>
      <div class="w200 mL10">
        <el-select placeholder="请选择用户角色" style="width: 100%;" filterable clearable v-model="role">
          <el-option v-for="item in roleArray" :key="item.roleId" :label="item.roleName" :value="item.roleId">
          </el-option>
        </el-select>
      </div>
      <div class="mL20">所属组织</div>
      <div class="w200 mL10">
        <el-select placeholder="请选择所属组织" style="width: 100%;" filterable clearable v-model="org">
          <el-option v-for="item in orgArray" :key="item.deptId" :label="item.deptName" :value="item.deptId">
          </el-option>
        </el-select>
      </div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickSearch">查询</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickClear">重置</div>
    </div>
    <div class="row-me row-center mT20">
      <div class="btn-blue w150 btn-height40" @click="clickAdd">添加系统用户</div>
    </div>
    <div class="column-me flex1 overflow-auto mT20">
      <div class="flex1 overflow-auto">
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style" height="100%">
          <el-table-column type="index" label="序号" width="80" align="center"/>
          <el-table-column prop="userName" label="用户账号" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="nickName" label="用户姓名" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="phonenumber" label="手机号" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="dept.deptName" label="所属组织" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="分配角色" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div v-if="scope.row.roles&&Array.isArray(scope.row.roles)">
                {{
                  scope.row.roles.map(item => {
                    return item.roleName
                  }).join(',')
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div v-if="scope.row.status==0">正常</div>
              <div v-if="scope.row.status==1">已冻结</div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip
                           width="200"></el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="250" fixed="right">
            <template #default="scope">
              <div class="row-me center-all">
                <div class="blue-text mL10" @click="clickEdit(scope.row)">编辑</div>
                <div class="blue-text mL10" @click="clickEditPwd(scope.row)">修改密码</div>
                <el-popconfirm title="您确认冻结吗？" width="250" @confirm="clickFreeze(scope.row,1)"
                               v-if="scope.row.status==0">
                  <template #reference>
                    <div class="blue-text mL10">冻结</div>
                  </template>
                </el-popconfirm>
                <el-popconfirm title="您确认解冻吗？" width="250" @confirm="clickFreeze(scope.row,0)"
                               v-if="scope.row.status==1">
                  <template #reference>
                    <div class="blue-text mL10">解冻</div>
                  </template>
                </el-popconfirm>
                <el-popconfirm title="您确认删除吗？" width="250" @confirm="clickDelete(scope.row)">
                  <template #reference>
                    <div class="red-text mL10">删除</div>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mT10">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 修改密码 -->
    <el-dialog title="修改密码" v-model="visiblePassword" width="35%" top="10vh" :destroy-on-close="true">
      <div class="padding20">
        <div class="row-me row-center">
          <div class="w90 text-justify">用户账号</div>
          <div>：</div>
          <div class="flex1 mL10">{{ detailItem.userName }}</div>
        </div>
        <div class="row-me row-center mT20">
          <div class="w90 text-justify">新密码</div>
          <div>：</div>
          <div class="flex1">
            <el-input placeholder="请输入6-12位密码" style="width: 100%;" :type="pwdType1" clearable :minlength="6"
                      :maxlength="12" v-model="password1" :class="{'red-border':showRedBorder&&password1===''}">
            </el-input>
          </div>
          <img
              :src="pwdType1==='password'?require('../../assets/img/eye_icon.png'):require('../../assets/img/not_eye_icon.png')"
              class="img-icon mL15 pointer" @click="clickPwd1">
        </div>
        <div class="row-me row-center mT20">
          <div class="w90 text-justify">确认密码</div>
          <div>：</div>
          <div class="flex1">
            <el-input placeholder="请输入6-12位密码" style="width: 100%;" :type="pwdType2" clearable :minlength="6"
                      :maxlength="12" v-model="password2" :class="{'red-border':showRedBorder&&password2===''}">
            </el-input>
          </div>
          <img
              :src="pwdType2==='password'?require('../../assets/img/eye_icon.png'):require('../../assets/img/not_eye_icon.png')"
              class="img-icon mL15 pointer" @click="clickPwd2">
        </div>
        <div class="row-me row-center flex-end1 mT100">
          <div class="btn-blank w140 btn-height40" @click="clickCancel">取消</div>
          <div class="btn-blue w140 btn-height40 mL20" @click="clickSave">提交</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :title="title" v-model="visibleAdd" width="600px" top="5vh" :destroy-on-close="true">
      <div class="column-me row-center">
        <div class="row-me row-center">
          <div class="left-div"><span class="red-star">*</span>用户账号:</div>
          <div class="w350">
            <el-input type="text" autocomplete="off" placeholder="请输入" style="width: 100%;" clearable
                      v-model="detailItem.userName" v-bind:autocomplete="noAutoComplete"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20" v-if="$method.isEmpty(detailItem.userId)">
          <div class="left-div"><span class="red-star">*</span>登录密码:</div>
          <div class="w350">
            <el-input placeholder="请输入" type="password" show-password autocomplete="off" style="width: 100%;"
                      clearable v-model="detailItem.password" v-bind:autocomplete="noAutoComplete"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20" v-if="$method.isEmpty(detailItem.userId)">
          <div class="left-div"><span class="red-star">*</span>确认密码:</div>
          <div class="w350">
            <el-input placeholder="请输入" type="password" show-password style="width: 100%;" clearable
                      v-model="detailItem.password1" v-bind:autocomplete="noAutoComplete"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="left-div"><span class="red-star">*</span>所属组织:</div>
          <div class="w350">
            <el-select placeholder="请选择组织" style="width: 100%;" filterable clearable
                       v-model="detailItem.deptId">
              <el-option v-for="item in orgArray" :key="item.deptId" :label="item.deptName" :value="item.deptId">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="left-div"><span class="red-star">*</span>分配角色:</div>
          <div class="w350">
            <el-select placeholder="请选择角色" style="width: 100%;" filterable clearable multiple
                       v-model="detailItem.roleIds">
              <el-option v-for="item in roleArray" :key="item.roleId" :label="item.roleName" :value="item.roleId">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="left-div"><span class="red-star">*</span>用户姓名:</div>
          <div class="w350">
            <el-input placeholder="请输入" style="width: 100%;" clearable v-model="detailItem.nickName"></el-input>
          </div>
        </div>
        <div class="row-me row-center mT20">
          <div class="left-div"><span class="red-star">*</span>手机号:</div>
          <div class="w350">
            <el-input placeholder="请输入绑定微信的手机号码" style="width: 100%;" clearable
                      @input="detailItem.phonenumber=$method.checkInput(detailItem.phonenumber,0)"
                      v-model="detailItem.phonenumber"></el-input>
          </div>
        </div>
        <div class="w100Per row-me row-center flex-end1 mT50">
          <div class="btn-blank w100 btn-height40 mR20" @click="visibleAdd=false">取消</div>
          <div class="btn-blue w100 btn-height40" @click="clickOK">确定</div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      account: '',
      name: '',
      role: '',
      roleArray: [],
      org: '',
      orgArray: [],
      tableData: [],
      pageNo: 1,
      total: 0,
      visiblePassword: false,
      password1: '',
      password2: '',
      pwdType1: 'password',
      pwdType2: 'password',
      showRedBorder: false,
      visibleAdd: false,
      title: '',
      detailItem: {},
      noAutoComplete: false,
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.getOrgArray()
    this.getRoleArray()
    this.noAutoComplete = true
    this.clickSearch()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    clickSearch() {
      this.pageNo = 1
      this.getData()
    },
    clickClear() {
      this.account = ''
      this.name = ''
      this.role = ''
      this.org = ''
      this.clickSearch()
    },
    clickAdd() {
      this.title = '新增'
      this.detailItem = {}
      this.visibleAdd = true
    },
    clickEdit(item) {
      this.title = '编辑'
      item.roleIds = item.roles.map(obj => {
        return obj.roleId
      })
      this.detailItem = this.$method.deepClone(item)
      this.visibleAdd = true
    },
    clickEditPwd(item) {
      this.password1 = ''
      this.password2 = ''
      this.detailItem = this.$method.deepClone(item)
      this.visiblePassword = true
    },
    clickPwd1() {
      this.pwdType1 = this.pwdType1 === 'password' ? 'text' : 'password'
    },
    clickPwd2() {
      this.pwdType2 = this.pwdType2 === 'password' ? 'text' : 'password'
    },
    //取消
    clickCancel() {
      this.visiblePassword = false
    },
    //提交
    clickSave() {
      if (this.password1 === '' || this.password2 === '') {
        this.showRedBorder = true
        return
      }
      if (this.password1.length < 6) {
        this.$message.error('密码不能低于6位')
        return
      }
      if (this.password1 !== this.password2) {
        this.$message.error('两次密码不一致')
        return
      }
      this.$put('/system/user/resetPwd', {
        userId: this.detailItem.userId,
        password: this.password1,
      }).then((res) => {
        if (res) {
          this.visiblePassword = false
          this.$message.success('修改成功')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //冻结解冻
    clickFreeze(item, status) {
      this.$put('/system/user/changeStatus', {
        userId: item.userId,
        status: status,
      }).then((res) => {
        if (res) {
          this.$message.success('操作成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //保存用户信息
    clickOK() {
      if (this.$method.isEmpty(this.detailItem.userName)) {
        this.$message.error("请输入用户账号")
        return
      }
      if (this.$method.isEmpty(this.detailItem.userId)) {
        if (this.$method.isEmpty(this.detailItem.password)) {
          this.$message.error("请输入登录密码")
          return
        }
        if (this.detailItem.password.length < 6 || this.detailItem.password.length > 12) {
          this.$message.error("登录密码需6-12位")
          return
        }
        if (this.detailItem.password !== this.detailItem.password1) {
          this.$message.error("两次密码不一致")
          return
        }
      } else {
        delete this.detailItem.password
      }
      if (this.$method.isEmpty(this.detailItem.deptId)) {
        this.$message.error("请选择组织")
        return
      }
      if (this.$method.isEmpty(this.detailItem.roleIds) || this.detailItem.roleIds.length === 0) {
        this.$message.error("请选择角色")
        return
      }
      if (this.$method.isEmpty(this.detailItem.nickName)) {
        this.$message.error("请输入用户姓名")
        return
      }
      if (this.$method.isEmpty(this.detailItem.phonenumber)) {
        this.$message.error("请输入用户手机号")
        return
      }
      if (this.detailItem.userId) {
        this.$put('/system/user', this.detailItem).then((res) => {
          if (res) {
            this.$message.success('保存成功')
            this.visibleAdd = false
            this.getData()
          }
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.$post('/system/user', this.detailItem).then((res) => {
          if (res) {
            this.$message.success('保存成功')
            this.visibleAdd = false
            this.clickSearch()
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getData()
    },
    //删除
    clickDelete(item) {
      this.$delete('/system/user/' + item.userId, {}).then((res) => {
        if (res) {
          this.$message.success('删除成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取组织下拉
    getOrgArray() {
      this.$get('/system/dept/list', {}).then((res) => {
        if (res) {
          this.orgArray = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    //获取角色下拉
    getRoleArray() {
      this.$get('/system/role/optionselect', {}).then((res) => {
        if (res) {
          this.roleArray = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getData() {
      this.$get('/system/user/list', {
        userName: this.account,
        nickName: this.name,
        roleId: this.role,
        deptId: this.org,
        pageNumber: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.systemUser {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .left-div {
    width: 120px;
    text-align: right;
    margin-right: 20px;
  }
}
</style>
